import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { getPostStatsNumberAfterSync } from '@/helper/utils'
import { apiService } from '@/services/api-services'
import { dispatcher, PostActionModel } from '@/stores/dispatcher'
import { walletStore } from '@/stores/wallet-store'
import { action, computed, observable } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

export class MostActiveDaoSectionController {
  @observable mostActiveDaos: any[] = []
  private _unsubcrible = new Subject()

  @observable page = 0
  @observable pageSize = 8
  @observable fetchApiCount = 0
  @observable showMoreLoading = false

  constructor() {
    this.fetchMostActiveDaos()
    dispatcher.$postChanged.pipe(takeUntil(this._unsubcrible)).subscribe((postAction) => {
      this.syncPostAction(postAction)
    })
  }

  destroy() {
    this._unsubcrible.next()
    this._unsubcrible.complete()
  }

  @action syncPostAction(postAction: PostActionModel) {
    if (!this.mostActiveDaos?.length || !postAction.daoId) return
    const userIndex = this.mostActiveDaos.findIndex((item) => item._id === postAction.daoId)
    if (userIndex !== -1) {
      this.mostActiveDaos[userIndex] = {
        ...this.mostActiveDaos[userIndex],
        totalPosts: getPostStatsNumberAfterSync(this.mostActiveDaos[userIndex].totalPosts, postAction.type),
      }
    }
  }

  @asyncAction *showMore() {
    this.showMoreLoading = true
    if ((this.page + 1) * this.pageSize >= this.mostActiveDaos.length) {
      this.fetchApiCount += 1
      yield this.fetchMostActiveDaos()
    } else {
      this.page += 1
    }
    this.showMoreLoading = false
  }

  @asyncAction *fetchMostActiveDaos() {
    try {
      this.mostActiveDaos = yield apiService.daos.getMostActiveDaos({
        page: this.fetchApiCount,
        pageSize: 32,
      })
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *joinDao(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.daos.joinDao({ daoId: item.id })
      item.isFollowing = true
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @asyncAction *leaveDao(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.daos.leaveDao({ daoId: item.id })
      item.isFollowing = false
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @computed get displayedDaos() {
    const start = this.page * this.pageSize
    return this.mostActiveDaos.slice(start, start + this.pageSize)
  }
}

export const mostActiveDaoController = new MostActiveDaoSectionController()
