



























































import GroupCover from '@/modules/community/common/components/group-cover.vue'
import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'
import { mostActiveDaoController } from './most-active-dao-section-controller'

@Observer
@Component({
  components: {
    GroupCover,
    'join-dao-button': () => import('../join-dao-button.vue'),
  },
})
export default class extends Vue {
  controller = mostActiveDaoController
  walletStore = walletStore
}
