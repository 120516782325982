
















import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class GroupCover extends Vue {
  @Prop({}) profile!: any
  @Prop({ default: '59' }) size!: any
  @Prop({ default: '10' }) borderRadius!: any
}
